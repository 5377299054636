<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h3>Backup de : {{ backup.client }}</h3>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <button @click="$router.go(-1)" class="btn btn-primary" style="margin-bottom: 20px;">Retour</button>
          </b-col>
        </b-row>
        
        <!-- TAB INFORMATIONS -->
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>Informations</span>
            </template>
            <div class="m-2">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  class="align-items-left justify-content-start mb-2 mb-md-0"
                >
                  <b-card-text><strong>Client :</strong> {{ this.backup.client }} </b-card-text>
                  <b-card-text><strong>Date :</strong> {{ this.backup.date }}</b-card-text>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="align-items-left justify-content-start mb-2 mb-md-0"
                >
                  <b-card-text><strong>Message d'erreur :</strong> {{ this.backup.erreur }} </b-card-text>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <!-- TAB DISQUE -->
          <b-tab>
            <template #title>
              <feather-icon icon="DiscIcon" />
              <span>Disque</span>
            </template>
            <div class="m-2">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="align-items-left justify-content-start mb-2 mb-md-0"
                >
                  <h2 style="text-align: center"><strong>Disque :</strong> {{ this.lettredisque }} </h2>

                  <vue-apex-charts
                    type="radialBar"
                    height="400"
                    :options="radialBarChart.chartOptions"
                    :series="radialBarChart.series"
                  />

                </b-col>
              </b-row>
            </div>
          </b-tab>
          <!-- TAB SAUVEGARDES -->
          <b-tab>
            <template #title>
              <feather-icon icon="ArchiveIcon" />
              <span>Sauvegardes</span>
            </template>

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Afficher</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entrées</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="filter"
                    class="d-inline-block mr-1"
                    placeholder="Recherche..."
                  />
                </div>
              </b-col>
            </b-row>
            <!-- Tableau -->
            <b-row>
              <b-col>
                <b-table
                  id="datatables"
                  class="mt-4 mb-4"
                  :items="Sauvegardes"
                  :fields="fields"
                  stacked="md"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  striped
                  small
                  @filtered="onFiltered"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              />
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-tab>
          <!-- TAB SQL -->
          <b-tab>
            <template #title>
              <feather-icon icon="DatabaseIcon" />
              <span>SQL</span>
            </template>

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Afficher</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entrées</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="filter"
                    class="d-inline-block mr-1"
                    placeholder="Recherche..."
                  />
                </div>
              </b-col>
            </b-row>
            <!-- Tableau -->
            <b-row>
              <b-col>
                <b-table
                  id="datatables"
                  class="mt-4 mb-4"
                  :items="SQLRapport"
                  stacked="md"
                  :per-page="perPage"
                  :current-page="currentPage"
                  striped
                  small
                  @filtered="onFiltered"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              />
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BTabs,
  BTab,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PouchDB from 'pouchdb'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTabs,
    BTab,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'fichier', label: 'Fichier', sortable: true },
        { key: 'date_sauvegarde', label: 'Date', sortable: true },
        { key: 'taille', label: 'Tailles', sortable: true },
      ],
      backup: [],
      lettredisque: '',
      Sauvegardes: [],
      SQLRapport: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      currentPage: 1,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      outlined: true,
      filterOn: ['fichier'],
      sortBy: 'date_sauvegarde',
      sortDirection: 'desc',
      sortDesc: false,
      radialBarChart: {
        series: [0, 0, 0, 0],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 185,
              hollow: {
                size: '25%',
              },
              track: {
                margin: 15,
              },
              dataLabels: {
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                },
                total: {
                  show: true,
                  fontSize: '1rem',
                  label: 'Disque',
                  formatter() {
                    return ''
                  },
                },
              },
            },
          },
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
          },
          stroke: {
            lineCap: 'round',
          },
          labels: ['Occupé ', 'Sauvegarde', 'SQL', 'Autres'],
        },
      },
    }
  },
  computed: {
    /**
      * Total no. of records
      */
    rows() {
      return this.items.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.createdview()
    this.timer = setInterval(this.createdview, 30000);  
  },
  customButtons: {
    default: true,
  },
  methods: {
    createdview() {
      this.localDB = new PouchDB('backup')
      this.remoteDB = new PouchDB('https://couchdb.placedusite.fr/backup')
      // this.localDB.sync(this.remoteDB, { live: true, retry: true })

      this.localDB.get(this.$route.params.id, this.setDatas)
    },
    setDatas(err, doc) {
      this.backup = []
      this.lettredisque = ''
      this.Sauvegardes = []
      this.SQLRapport = []

      if (err) {
        console.log('err', err)
      }
      // console.log('doc', doc)
      this.backup = doc


      // ONGLET DISQUE
      this.lettredisque = this.backup.disques[0].disque

      this.disqueSpace = Number(doc.disques[0].disque_occupe.replace(",", ".")) + Number(doc.disques[0].disque_libre.replace(",", "."))
      this.disqueAutre = Number(doc.disques[0].disque_libre.replace(",", ".")) - Number(doc.disques[0].disque_sauv.replace(",", ".")) - Number(doc.disques[0].disque_data.replace(",", "."))
      this.disqueOccupePourcentage = (Number(doc.disques[0].disque_occupe.replace(",", ".")) * 100) / this.disqueSpace
      this.disqueAutrePourcentage = (this.disqueAutre * 100) / this.disqueSpace
      this.disqueBackupPourcentage = (doc.disques[0].disque_sauv.replace(",", ".") * 100) / this.disqueSpace
      this.disqueSqlPourcentage = (doc.disques[0].disque_data.replace(",", ".") * 100) / this.disqueSpace
      
      this.radialBarChart.series = [parseFloat(this.disqueOccupePourcentage).toFixed(0), parseFloat(this.disqueBackupPourcentage ).toFixed(0), parseFloat(this.disqueSqlPourcentage).toFixed(0), parseFloat(this.disqueAutrePourcentage).toFixed(0)]

      // ONGLET SAUVEGARDE
      if (doc.sauvegardes !== 'undefined' || doc.sauvegardes !== null) {
        for (let i = 0; i < doc.sauvegardes.length; i++) {
          this.Sauvegardes.push({
            fichier: doc.sauvegardes[i].fichier,
            date_sauvegarde: doc.sauvegardes[i].date_sauvegarde,
            taille: doc.sauvegardes[i].taille,
          })
        }
        this.totalRows = this.Sauvegardes.length
      }

      // ONGLET SQL
      if (doc.sql !== 'undefined' || doc.sql !== null) {
        this.SQLRapport = doc.sql
      }

    },
    formatDateAssigned(value) {
      const formattedDate = DateTime.fromISO(
        value
      ).setLocale('fr').toFormat('dd LLL yyyy à hh:mm')
      return formattedDate;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
    .per-page-selector {
      width: 90px;
    }
    .btNopadding button {
      padding: 0;
    }
    @media (max-width: 767.98px) {
      .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
        text-align: left;
      }
    }
</style>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
